<template>
  <div>
    <div class="warp-login-quick-bg">
      <div class="warp-login-quick-box">
        <div class="box-quick-header">
          <div class="box-quick-title">用户登录</div>

          <back-arrow></back-arrow>
        </div>
        <div class="box-quick-content">
          <div class="quick-box">
            <div class="quick-box-login" v-if="isShow">
              <van-field
                class="login-num"
                left-icon="friends-o"
                v-model="quickLogins.username"
                placeholder="请输入账号"
              ></van-field>
            </div>
            <div class="box-content-quick-login">
              <button
                @click="userQuickLogin"
                class="box-content-quick-login-btn"
              >
                <span class="box-quick-span">快速游戏</span>
              </button>
            </div>
          </div>
          <van-divider
            ><span class="login-mode">或者通过以下方式登录</span></van-divider
          >
          <div class="box-content-btn">
            <div class="code" @click="toPhoneCode">
              <img src="../../assets/image/icon_sec-code.png" />
              <span class="text-group_1-1" style="color: #000;">手机验证</span>
            </div>
            <div class="user" @click="toAccount">
              <img src="../../assets/image/icon_code.png" />
              <span class="text-group_1-2" style="color: #000;">账号密码</span>
            </div>
          </div>
          <div class="box-content-register">
            <button @click="userRegister" class="box-content-register-btn">
              <span class="box-content-span">账号注册</span>
            </button>
          </div>
          <div class="rule">
            <div class="rule-content">
              <van-checkbox
                shape="square"
                v-model="quickCheck"
                icon-size="16"
                class="rule-check"
              ></van-checkbox>
              <div class="rule-agreement">
                <span>我已阅读并同意</span>
                <span @click="userProcotol">用户协议</span>
                <span>和</span>
                <span @click="privacyPolicy">隐私政策</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <van-dialog v-model="quickShow" :showConfirmButton="false">
        <div><h3>注册成功</h3></div>
        <div class="ac-box">
          <div class="account">账号:{{ registerCode.username }}</div>
          <div class="pad">密码:{{ registerCode.passWord }}</div>
          <div class="ac-span">
            请妥善保管,如有疑问请联系客服
          </div>
          <van-button
            round
            type="info"
            @click="toGame"
            size="small"
            class="gameBtn"
            ><span>进入游戏</span></van-button
          >
        </div>
        <div style="display: none;">
          <button
            class="copyBtn"
            data-clipboard-action="copy"
            id="copyTrigger"
            :data-clipboard-text="copyUP"
            @click="getCopyWrap"
          ></button>
        </div>
      </van-dialog>
    </div>
  </div>
</template>
<script>
import Clipboard from 'clipboard';
import { Toast } from 'vant';
import { uuid } from '@/util/getUuid';
import { quickLogin } from '@/service/moudules/wrapLogin';
import { setLocalStorage, getLocalStorage } from '@/util/userLoginFn';
import { isRegister } from '@/util/certification';
import { checkInviteCode } from '@/service/moudules/platformApi';
import backArrow from './components/backArrow.vue';
export default {
  components: {
    backArrow,
  },
  data() {
    return {
      uuid,
      isRegister,
      setLocalStorage,
      getLocalStorage,
      quickLogin,
      checkInviteCode,
      quickCheck: false,
      quickShow: false,
      quick: {
        num: '',
      },
      goGame: 6,
      timer: null,
      registerCode: {
        username: '',
        passWord: '',
      },
      quickLogins: {
        username: '',
      },
      gameObj: {},
      msg: '',
      isShow: false,
      inviteCode: '',
      copyUP: '',
    };
  },
  methods: {
    getCopyWrap() {
      //使用main.js内注册的vue原型，用class绑定将数据存储到clipboard中
      try {
        navigator.clipboard
          .writeText(this.copyUP)
          .then(() => {
            Toast.success({
              message: '复制账号密码成功！',
              type: 'success',
            });
          })
          .catch(() => {
            Toast.fail('复制失败，请手动选择复制！');
          });
      } catch (error) {
        console.log(error, 'error');
      }
    },
    userProcotol() {
      this.$router.push({
        path: '/userProcotol',
      });
    },
    privacyPolicy() {
      this.$router.push({
        path: '/privacyPolicy',
      });
    },
    toPhoneCode() {
      this.$router.push({
        path: '/phone',
      });
    },
    toAccount() {
      this.$router.push({
        path: '/account',
      });
    },
    loginBtn() {
      console.log('111');
    },
    userRegister() {
      try {
      } catch (error) {
        console.log(error);
      }
    },
    async toGame() {
      try {
        this.quickShow = false;
        await this.isRegister(this.gameObj, this.gameMsg);
        this.goGame = 6;
        clearInterval(this.timer);
      } catch (error) {
        console.log(error);
      }
    },
    async userQuickLogin() {
      try {
        try {
          if (!this.quickCheck) {
            Toast.fail('请阅读相关协议后再进行游戏');
            return false;
          }

          await this.goIsGame();

          //this.countdown();
        } catch (error) {
          console.log(error);
        }
      } catch (error) {
        console.log(error);
      }
    },
    // countdown() {
    //   this.timer = setInterval(async () => {
    //     this.goGame - 1;
    //     if (this.goGame === 0) {
    //       this.quickShow = false;
    //       await this.isRegister(this.gameObj, this.gameMsg);
    //       this.goGame = 6;
    //       clearInterval(this.timer);
    //     }
    //   }, 1000);
    // },
    async goIsGame() {
      try {
        let params = {
          inviteCode: this.inviteCode,
          uuid: this.getLocalStorage('uuid'),
        };

        let iParams = {
          inviteCode: this.inviteCode,
        };

        let res = await checkInviteCode(iParams);

        if (res.msg === 'false') {
          Toast.fail('邀请码有误,请检查~');
          this.setLocalStorage('inviteCode', '');
          return false;
        }

        let { code, msg, data } = await this.quickLogin(params);
        if (code !== 200) {
          Toast.fail(msg);
          return false;
        }

        let { userName, password } = data;
        this.gameObj = data;
        this.gameMsg = msg;
        this.copyUP = `账号:${userName}\n密码:${password}`;
        this.registerCode.username = userName;
        this.registerCode.passWord = password;
        this.quickLogins.username = userName;
        this.getCopyWrap();
        this.quickShow = true;
      } catch (error) {
        console.log(error);
      }
    },
  },

  async created() {
    try {
      this.isShow = false;
      this.inviteCode = this.getLocalStorage('inviteCode');
      let isUuId = this.getLocalStorage('uuid');
      if (isUuId === null || isUuId === undefined) {
        this.setLocalStorage('uuid', this.uuid());

        return false;
      }

      if (isUuId !== '') {
        await this.goIsGame();
        this.quickCheck = true;
        this.isShow = true;
        setTimeout(() => {
          this.isRegister(this.gameObj, this.gameMsg);
        }, 2000);
        this.isShow = true;
        return false;
      }

      // this.goIsGame();
    } catch (error) {
      console.log(error);
    }
  },
};
</script>
<style lang="less" scoped>
.warp-login-quick-bg {
  height: 100vh;
  background-image: url('../../assets/image/blogin-bg.jpg');
  background-position: 50% 50%;
  background-size: cover;
  .warp-login-quick-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 345px;
    height: 492px;
    background: #fff;
    box-shadow: 0px 2px 16px 0px rgba(12, 28, 65, 0.2);
    border-radius: 11px;
    border: 2px solid;
    // border-image: linear-gradient(
    //     224deg,
    //     rgba(255, 255, 255, 0.5),
    //     rgba(255, 255, 255, 0.33),
    //     rgba(255, 255, 255, 0.08)
    //   )
    //   2 2;
    //  backdrop-filter: blur(6px);
  }
  .box-quick-header {
    display: flex;
    justify-content: center;
    align-items: center;
    .box-quick-title {
      position: relative;
      color: #000;
      font-size: 18px;
      margin-top: 20px;
    }
    .el-icon-question {
      position: absolute;
      top: 23px;
      right: 20px;
      font-size: 18px;
      color: #000;
    }
  }
  .box-quick-content {
    .quick-box {
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      justify-content: center;
      align-items: center;
      .quick-box-login {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #e5e7ed;
        border-radius: 6px;
        width: 294px;

        .login-num {
          overflow-wrap: break-word;
          color: #000;
          font-size: 14px;
          white-space: nowrap;
          line-height: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: none;
        }
      }
      .box-content-quick-login {
        display: flex;
        justify-content: center;
        align-items: center;
        .box-content-quick-login-btn {
          border-radius: 23px;
          border: 1px solid;
          border-image: linear-gradient(to right, #785fba, #4d53b0, #2e4bb6);
          background-image: linear-gradient(
            to right,
            #785fba,
            #4d53b0,
            #2e4bb6
          );
          margin-top: 24px;
          padding: 9px 110px 8px 110px;
          .box-quick-span {
            overflow-wrap: break-word;
            color: #fff;
            font-size: 18px;
            font-family: PingFangSC-Medium;
            font-weight: 500;

            white-space: nowrap;
            line-height: 25px;
          }
        }
      }
    }

    .box-content-btn {
      width: 100%;
      display: flex;
      height: 120px;
      align-items: center;
      justify-content: space-around;
      .login {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        object-fit: contain;
        & img {
          width: 50px;
          height: 50px;
        }
      }
      .code {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        & img {
          width: 50px;
          height: 50px;
        }
      }
      .user {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        & img {
          width: 50px;
          height: 50px;
        }
      }
    }
  }
  .login-mode {
    color: #000;
  }
  .box-content-register {
    .box-content-register-btn {
      border-radius: 23px;
      border: 2px solid;
      margin-top: 14px;
      padding: 9px 110px 8px 110px;
      border-image: linear-gradient(to right, #785fba, #4d53b0, #2e4bb6);
      background-image: linear-gradient(to right, #785fba, #4d53b0, #2e4bb6);
      .box-content-span {
        overflow-wrap: break-word;
        color: #fff;
        font-size: 18px;
        font-family: PingFangSC-Medium;
        font-weight: 500;
        text-align: left;
        white-space: nowrap;
        line-height: 25px;
      }
    }
  }
  .rule {
    margin-top: 20px;
    .rule-content {
      display: flex;
      justify-content: center;
      align-items: center;
      .rule-check {
        margin-right: 10px;
      }
      .rule-agreement {
        color: #000;
        font-size: 14px;
        font-size: 14px;
        font-family: PingFang-SC-Medium;

        & :nth-child(2) {
          color: #815fe7;
          font-size: 14px;
          font-size: 14px;
          font-family: PingFang-SC-Medium;
        }
        & :nth-child(4) {
          color: #815fe7;
          font-size: 14px;
          font-size: 14px;
          font-family: PingFang-SC-Medium;
        }
      }

      .policy {
      }
    }
  }
}
.ac-box {
  height: 250px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  .gameBtn {
    width: 100px;
  }
  .ac-span {
    color: #29b8db;
  }
}
</style>
